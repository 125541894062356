@import "../../scss/Colors";
@import "../../scss/Text";
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-pro/scss/duotone";

.teaserWebApps {

}

.listStyle {

	ul {
		list-style: none;
		padding:    0;

		li {
			position:      relative;
			@include typeStyledSmall;

			padding-left:  30px;
			margin-bottom: 20px;
			color:         $Color_Primary;

			&:after {
				position: absolute;
				top:      3px;
				left:     0;
				color:    $Color_Secondary;
				@extend %fa-icon;
				@extend .fad;
				content:  fa-content($fa-var-chevron-double-right);
			}
		}
	}
}
