$Desktop_Minimum_Width: 1200px !default;
$Mobile_Minimum_Width:  360px !default;

// Breakpoints
$Breakpoint_Xs:         "360px" !default;
$Breakpoint_Small:      "540px" !default;
$Breakpoint_Medium:     "768px" !default;
$Breakpoint_Large:      "960px" !default;
$Breakpoint_XL:         "1140px" !default;
$Breakpoint_HD:         "1900px" !default;
$Breakpoint_UHD:        "2500px" !default;


@mixin labelStyles {
	font-family: Light, sans-serif !important;
}

@mixin controlStyles {
	font-family: Regular, sans-serif !important;
	color:       $Color_Text;

	padding:     5px;
	background:  transparentize($Color_Primary, 0.9);
}

@mixin controlReadonlyStyles {
	background: #EEEEEE;
}

@mixin headline2 {
	font-family: Medium, sans-serif;
	font-size:   1.2rem;
	color:       $Color_Primary;
}

@mixin textblob {

	font-family: Regular, sans-serif;
	font-size:   1rem;
	color:       $Color_Text;
	line-height: 1.4;
}

@mixin topLine {
	color:          #B4B4B4;
	font-family:    Medium, sans-serif;
	text-transform: uppercase;
	font-size:      1rem;
}


@mixin animationRotation {

	@keyframes rotation {
		from {
			-ms-transform:     rotate(0deg);
			-moz-transform:    rotate(0deg);
			-webkit-transform: rotate(0deg);
			-o-transform:      rotate(0deg);
			transform:         rotate(0deg);
		}
		to {
			-ms-transform:     rotate(360deg);
			-moz-transform:    rotate(360deg);
			-webkit-transform: rotate(360deg);
			-o-transform:      rotate(360deg);
			transform:         rotate(360deg);
		}
	}

}

