@import "../../../scss/Colors";

.Quote {
	position:    relative;
	border-left: 5px solid $Color_Secondary_Light;
	padding:     0 10px 15px 10px;
	box-sizing:  content-box;
	max-width:   400px;
	top:         30px;

	svg {
		position:  absolute;
		color:     #EEEEEE;
		font-size: 3rem;
		right:     50px;
		border:    10px;
	}


	p {
		font-family: Handwrite, sans-serif;
		color:       $Color_Primary;
		font-size:   1.5rem;
		margin:      0;
	}

	.avatarWrapper {
		position:        relative;
		display:         flex;
		width:           100%;
		justify-content: flex-end;
		align-items:     flex-end;
		flex-direction:  column;
		max-width:       400px;
		padding-right:   30px;

		.quoteAvatar {
			position: relative;
			width:    100px;
			height:   100px;
		}

		span {
			color:           $Color_Text_Light;
			position:        relative;
			top:             8px;
			display:         flex;
			width:           100%;
			justify-content: flex-end;

		}
	}

}
