@import "../scss/Settings";
@import "../scss/Colors";
@import "../scss/Content";

.RenderAPITeaser {

	@include typoStyles;

	.topLine {
		@include topLine;
	}

	.columns {

		position:              relative;
		display:               grid;
		grid-template-columns: 3fr 2fr;

		@media (max-width: $Breakpoint_Large) {
			display:        flex;
			flex-direction: column;
		}

		.teaser {

			position:        relative;
			width:           100%;
			height:          100%;
			display:         flex;
			justify-content: center;
			align-items:     center;
			transform-style: preserve-3d;

			> * {
				transition: 500ms ease-in-out;
				opacity:    0;
			}

			.blLogo {
				position: relative;
				left:     -20%;
				width:    15%;


				&.show {
					opacity: 1;
					left:    0;
				}

			}

			.plus {
				width:  10%;
				margin: 0 20px;

				&.show {
					opacity: 1;
				}
			}

			.pfLogo {
				position: relative;
				width:    40%;
				right:    -20%;

				&.show {
					opacity: 1;
					right:   0;
				}
			}

			.claim {
				position:    absolute;
				width:       max-content;
				bottom:      -15%;
				color:       $Color_Secondary;
				padding:     10px;
				font-family: Handwrite, sans-serif;
				font-size:   1.5rem;


				&.show {
					opacity:   1;
					left:      13%;
					bottom:    15%;
					transform: rotate(-2deg);
				}
			}
		}

	}

}
