@import "Colors";
@import "Settings";

body {
    background: $Background;
}


ion-content {
    --background: none;
    background:   $Background;
}
