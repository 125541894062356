@mixin transitionEaseInOutFast($time) {
	transition:                 all $time cubic-bezier(0.680, 0.450, 0.030, 0.870); /* custom */
	transition-timing-function: cubic-bezier(0.680, 0.450, 0.030, 0.870); /* custom */
}

@mixin transitionEaseInOutSlow($time) {
	transition: all $time cubic-bezier(1.000, -0.600, 1.000, 1.650); /* custom */

	transition-timing-function: cubic-bezier(1.000, -0.600, 1.000, 1.650); /* custom */
}
