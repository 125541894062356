@import "./Settings";
@import "./Text";
@import "./Colors";

@mixin typoStyles {

	p {
		line-height: 1.6;
	}

	strong {
		color: $Color_Primary;
	}

}

@mixin contentBox {

	position:        relative;
	width:           100%;
	display:         flex;
	justify-content: center;
	align-items:     center;

	margin-bottom:   50px;

	@include typoStyles;

	> div {
		position:       relative;
		max-width:      1200px;
		width:          100%;
		display:        flex;
		flex-direction: column;

		@media (max-width: $Desktop_Minimum_Width) {
			padding-left:  20px;
			padding-right: 20px;
		}

		@media (max-width: $Breakpoint_Small) {
			padding-left:  10px;
			padding-right: 10px;
		}

		&.expand {
			width:     100%;
			max-width: 100%;
		}

		&.noPadding {
			padding: 0 !important;
		}

		> span,
		.columnTitle {
			@include textTopLabel;
		}
	}
}
