@import "../../scss/Colors";
@import "../../scss/Text";
@import "../../scss/Settings";

.TeaserW2p {
	position:        relative;
	width:           100%;
	display:         flex;
	flex-direction:  column;
	justify-content: flex-start;
	align-items:     flex-start;

	.columns {
		column-count:  2;
		column-gap:    30px;
		padding-right: 20px;

		@media (max-width: $Breakpoint_Medium) {
			column-count: 1;

		}

		.sublineButton {
			position:   relative;
			left:       0px;
			width:      max-content;

			margin-top: 20px;
			font-size:  1rem;
		}
	}

	.teaserWrapper {
		position:        relative;
		width:           100%;
		display:         flex;
		flex-direction:  column;
		justify-content: center;
		align-items:     center;
	}

	.teaserStage {

		position:       relative;
		width:          1200px;
		height:         250px;
		display:        flex;
		flex-direction: column;
		left:           8%;

		.browser {
			position: absolute;
			width:    10%;
		}

		.mobil {
			position:  absolute;
			width:     8%;
			top:       9%;
			left:      8%;
			transform: rotate(20deg);
		}

		.arrow1 {
			position:  absolute;
			width:     15%;
			top:       -3%;
			left:      18%;
			transform: rotate(120deg);
		}

		.cloud {
			position: absolute;
			width:    15%;
			top:      -3%;
			left:     35%;
		}

		.logo {
			position: absolute;
			width:    7%;
			top:      17%;
			left:     35%;
		}

		.arrow2 {
			position:  absolute;
			width:     19%;
			top:       -8%;
			left:      53%;
			transform: rotate(120deg);
		}

		.flyer {
			position: absolute;
			width:    12%;

			top:      0%;
			left:     75%;
		}


	}
}
