.padding {

    position: relative;
    display: flex;
    width: 100%;

    padding: 20px;
    margin: 0 auto;

    > div {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;

        flex-direction: column;
    }

    h3 {
        display: block;
    }

}
