@import "../../../scss/Animations";
@import "../../../scss/Settings";
@import "../../../scss/Colors";
@import "../../../scss/Content";

.ProductItem {

	@include typoStyles;

	position:       relative;
	display:        flex;
	flex-direction: column;

	border-left:    5px solid $Color_Secondary;
	padding:        5px 10px 20px 10px;
	margin-bottom:  45px;
	height:         100%;
	background:     #fefefe;

	h2 {

		padding:     5px 0;
		margin:      0;
		font-size:   1.6rem;
		font-family: Medium, sans-serif !important;
	}

	h3 {
		padding:   5px 0;
		margin:    0;
		font-size: 1rem;
		color:     $Color_Text_Light;
	}

	ul {

		list-style:     none;
		display:        flex;
		flex-direction: row;
		flex-wrap:      wrap;
		padding:        0;
		margin:         0;
		position:       absolute;
		bottom:         10px;


		li {

			color:         $Color_Primary;
			font-family:   Light, sans-serif;
			background:    transparentize($Color_Primary, .9);
			font-size:     0.8rem;
			padding:       5px 12px;
			border-radius: 15px;
			margin-right:  5px;
			margin-bottom: 5px;
		}
	}

}
