@import "../scss/Settings";
@import "../scss/Colors";

.Integrations {

	position:              relative;
	display:               grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	@media (max-width: $Breakpoint_XL) {
		grid-template-columns: 1fr 1fr;
		grid-gap:              10px;
	}

	@media (max-width: $Breakpoint_Medium) {
		display:        flex;
		flex-direction: column;
	}

	> div {
		position: relative;
	}

	.IntegrationsCard {

		position:   relative;

		@media (max-width: $Breakpoint_Medium) {
			margin-left:  0;
			margin-right: 0;
		}

		box-shadow: none;

		ion-card-header {

			@media (max-width: $Breakpoint_Medium) {
				display:               grid;
				grid-template-columns: 1fr 6fr;
				grid-template-areas: "icon title" "icon subtitle";
				grid-gap:              0 15px;
				padding:               10px;
			}

			> div {
				width:           100%;
				display:         flex;
				flex-direction:  column;
				justify-content: center;
				align-items:     center;
				margin-bottom:   15px;
				grid-area:       icon;

				ion-img {
					width: 50%;

					@media (max-width: $Breakpoint_Medium) {
						width: 100%;
					}
				}
			}

			ion-card-title {
				font-family:     Regular, sans-serif;
				grid-area:       title;
				font-size:       2rem;
				display:         flex;
				flex-direction:  column;
				justify-content: center;

				@media (min-width: $Breakpoint_XL) {
					font-size: 1.5rem !important;

				}

			}

			ion-card-subtitle {
				grid-area:       subtitle;
				flex-direction:  column;
				justify-content: center;
			}

			ion-card-content {
				@media (max-width: $Breakpoint_Medium) {
					padding: 10px;
				}
			}
		}
	}
}
