.cloudApi {
    position: absolute;
    left:     0;
    top:      0%;
    width:    100%;
    height:   100%;
    display:  flex;
    max-width: 400px;

    .textCloud {
        position: absolute;
        top:      -5%;
        left:     20%;
        opacity:  0;

        &.show {
            top:     0%;
            opacity: 1;
        }
    }

    .cloud1 {
        position:        absolute;
        top:             -5%;
        left:            -100%;
        width:           40%;
        opacity:         0;
        color: white;
        --color: none;


        &.show {
            left:    0%;
            opacity: 1;
        }

        animation:       cloudmove 5s infinite;
        animation-delay: 1s;
    }

    .cloud2 {
        position:        absolute;
        top:             20%;
        left:            80%;
        width:           30%;
        opacity:         0;

        animation:       cloudmove 4s infinite;
        animation-delay: 0s;

        &.show {
            left:    55%;
            opacity: 1;
        }
    }

    .cloud3 {
        position:        absolute;
        top:             25%;
        left:            0%;
        width:           52%;
        opacity:         0;
        transition:      500ms ease-in-out;
        animation:       cloudmove 6s infinite;
        animation-delay: 1.5s;

        &.show {
            left:    20%;
            opacity: 1;
        }
    }

    .logo {
        position:   absolute;
        top:        35%;
        left:       40%;
        width:      15%;
        opacity:    0;
        transition: 1000ms ease-in-out;
        animation:  cloudmove 3s infinite;

        &.show {
            top:     25%;
            left:    40%;
            opacity: 1;
        }
    }
}
