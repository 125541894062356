@import "../scss/Settings";
@import "../scss/Colors";
@import "../scss/Content";

.ContentBox {
	position: relative;

	@include typoStyles;

	&.col1 {
		display:        flex;
		flex-direction: column;
	}

	&.col2 {
		display:               grid;
		grid-template-columns: 1fr 1fr;
		grid-gap:              30px;

		@media (max-width: $Breakpoint_Large) {
			display:        flex;
			flex-direction: column;
		}
	}


}
