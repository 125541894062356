/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary:                #26406C;
	--ion-color-primary-rgb:            38, 64, 108;
	--ion-color-primary-contrast:       #FFFFFF;
	--ion-color-primary-contrast-rgb:   255, 255, 255;
	--ion-color-primary-shade:          #21385F;
	--ion-color-primary-tint:           #3C537B;

	/** secondary **/
	--ion-color-secondary:              #00A30B;
	--ion-color-secondary-rgb:          0, 163, 11;
	--ion-color-secondary-contrast:     #FFFFFF;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade:        #008F0A;
	--ion-color-secondary-tint:         #1AAC23;

	/** tertiary **/
	--ion-color-tertiary:               #5260FF;
	--ion-color-tertiary-rgb:           82, 96, 255;
	--ion-color-tertiary-contrast:      #FFFFFF;
	--ion-color-tertiary-contrast-rgb:  255, 255, 255;
	--ion-color-tertiary-shade:         #4854E0;
	--ion-color-tertiary-tint:          #6370FF;

	/** success **/
	--ion-color-success:                #00B802;
	--ion-color-success-rgb:            45, 211, 111;
	--ion-color-success-contrast:       #FFFFFF;
	--ion-color-success-contrast-rgb:   255, 255, 255;
	--ion-color-success-shade:          #28BA62;
	--ion-color-success-tint:           #42D77D;

	/** warning **/
	--ion-color-warning:                #FFC409;
	--ion-color-warning-rgb:            255, 196, 9;
	--ion-color-warning-contrast:       #000000;
	--ion-color-warning-contrast-rgb:   0, 0, 0;
	--ion-color-warning-shade:          #E0AC08;
	--ion-color-warning-tint:           #FFCA22;

	/** danger **/
	--ion-color-danger:                 #EB445A;
	--ion-color-danger-rgb:             235, 68, 90;
	--ion-color-danger-contrast:        #FFFFFF;
	--ion-color-danger-contrast-rgb:    255, 255, 255;
	--ion-color-danger-shade:           #CF3C4F;
	--ion-color-danger-tint:            #ED576B;

	/** dark **/
	--ion-color-dark:                   #222428;
	--ion-color-dark-rgb:               34, 36, 40;
	--ion-color-dark-contrast:          #FFFFFF;
	--ion-color-dark-contrast-rgb:      255, 255, 255;
	--ion-color-dark-shade:             #1E2023;
	--ion-color-dark-tint:              #383A3E;

	/** medium **/
	--ion-color-medium:                 #92949C;
	--ion-color-medium-rgb:             146, 148, 156;
	--ion-color-medium-contrast:        #FFFFFF;
	--ion-color-medium-contrast-rgb:    255, 255, 255;
	--ion-color-medium-shade:           #808289;
	--ion-color-medium-tint:            #9D9FA6;

	/** light **/
	--ion-color-light:                  #F4F5F8;
	--ion-color-light-rgb:              244, 245, 248;
	--ion-color-light-contrast:         #000000;
	--ion-color-light-contrast-rgb:     0, 0, 0;
	--ion-color-light-shade:            #D7D8DA;
	--ion-color-light-tint:             #F5F6F9;
}

body.dark {
	--ion-color-primary:                #26406C;
	--ion-color-primary-rgb:            38, 64, 108;
	--ion-color-primary-contrast:       #FFFFFF;
	--ion-color-primary-contrast-rgb:   255, 255, 255;
	--ion-color-primary-shade:          #21385F;
	--ion-color-primary-tint:           #3C537B;

	--ion-color-secondary:              #00A30B;
	--ion-color-secondary-rgb:          0, 163, 11;
	--ion-color-secondary-contrast:     #FFFFFF;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade:        #008F0A;
	--ion-color-secondary-tint:         #1AAC23;

	--ion-color-tertiary:               #6A64FF;
	--ion-color-tertiary-rgb:           106, 100, 255;
	--ion-color-tertiary-contrast:      #FFFFFF;
	--ion-color-tertiary-contrast-rgb:  255, 255, 255;
	--ion-color-tertiary-shade:         #5D58E0;
	--ion-color-tertiary-tint:          #7974FF;

	--ion-color-success:                #2FDF75;
	--ion-color-success-rgb:            47, 223, 117;
	--ion-color-success-contrast:       #000000;
	--ion-color-success-contrast-rgb:   0, 0, 0;
	--ion-color-success-shade:          #29C467;
	--ion-color-success-tint:           #44E283;

	--ion-color-warning:                #FFD534;
	--ion-color-warning-rgb:            255, 213, 52;
	--ion-color-warning-contrast:       #000000;
	--ion-color-warning-contrast-rgb:   0, 0, 0;
	--ion-color-warning-shade:          #E0BB2E;
	--ion-color-warning-tint:           #FFD948;

	--ion-color-danger:                 #FF4961;
	--ion-color-danger-rgb:             255, 73, 97;
	--ion-color-danger-contrast:        #FFFFFF;
	--ion-color-danger-contrast-rgb:    255, 255, 255;
	--ion-color-danger-shade:           #E04055;
	--ion-color-danger-tint:            #FF5B71;

	--ion-color-dark:                   #F4F5F8;
	--ion-color-dark-rgb:               244, 245, 248;
	--ion-color-dark-contrast:          #000000;
	--ion-color-dark-contrast-rgb:      0, 0, 0;
	--ion-color-dark-shade:             #D7D8DA;
	--ion-color-dark-tint:              #F5F6F9;

	--ion-color-medium:                 #989AA2;
	--ion-color-medium-rgb:             152, 154, 162;
	--ion-color-medium-contrast:        #000000;
	--ion-color-medium-contrast-rgb:    0, 0, 0;
	--ion-color-medium-shade:           #86888F;
	--ion-color-medium-tint:            #A2A4AB;

	--ion-color-light:                  #222428;
	--ion-color-light-rgb:              34, 36, 40;
	--ion-color-light-contrast:         #FFFFFF;
	--ion-color-light-contrast-rgb:     255, 255, 255;
	--ion-color-light-shade:            #1E2023;
	--ion-color-light-tint:             #383A3E;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */
.ios body.dark {
	--ion-background-color:     #413D3C;
	--ion-background-color-rgb: 0, 0, 0;

	--ion-text-color:           #FFFFFF;
	--ion-text-color-rgb:       255, 255, 255;

	--ion-color-step-50:        #0D0D0D;
	--ion-color-step-100:       #1A1A1A;
	--ion-color-step-150:       #262626;
	--ion-color-step-200:       #333333;
	--ion-color-step-250:       #404040;
	--ion-color-step-300:       #4D4D4D;
	--ion-color-step-350:       #595959;
	--ion-color-step-400:       #666666;
	--ion-color-step-450:       #737373;
	--ion-color-step-500:       #808080;
	--ion-color-step-550:       #8C8C8C;
	--ion-color-step-600:       #999999;
	--ion-color-step-650:       #A6A6A6;
	--ion-color-step-700:       #B3B3B3;
	--ion-color-step-750:       #BFBFBF;
	--ion-color-step-800:       #CCCCCC;
	--ion-color-step-850:       #D9D9D9;
	--ion-color-step-900:       #E6E6E6;
	--ion-color-step-950:       #F2F2F2;

	--ion-toolbar-background:   #545251;

	--ion-item-background:      #545251;

	--ion-card-background:      #545251;
}


/*
 * Material Design Dark Theme
 * -------------------------------------------
 */
.md body.dark {
	--ion-background-color:     #413D3C;
	--ion-background-color-rgb: 18, 18, 18;

	--ion-text-color:           #FFFFFF;
	--ion-text-color-rgb:       255, 255, 255;

	--ion-border-color:         #222222;

	--ion-color-step-50:        #1E1E1E;
	--ion-color-step-100:       #2A2A2A;
	--ion-color-step-150:       #363636;
	--ion-color-step-200:       #414141;
	--ion-color-step-250:       #4D4D4D;
	--ion-color-step-300:       #595959;
	--ion-color-step-350:       #656565;
	--ion-color-step-400:       #717171;
	--ion-color-step-450:       #7D7D7D;
	--ion-color-step-500:       #898989;
	--ion-color-step-550:       #949494;
	--ion-color-step-600:       #A0A0A0;
	--ion-color-step-650:       #ACACAC;
	--ion-color-step-700:       #B8B8B8;
	--ion-color-step-750:       #C4C4C4;
	--ion-color-step-800:       #D0D0D0;
	--ion-color-step-850:       #DBDBDB;
	--ion-color-step-900:       #E7E7E7;
	--ion-color-step-950:       #F3F3F3;

	--ion-item-background:      #545251;

	--ion-toolbar-background:   #545251;

	--ion-tab-bar-background:   #545251;

	--ion-card-background:      #545251;
}
