@import "../../../scss/Animations";
@import "../../../scss/Settings";
@import "../../../scss/Colors";
@import "../../../scss/Content";

@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid";

.W2PGuide {

    position:       relative;
    width:          100%;
    display:        flex;
    flex-direction: column;
    align-items:    center;

    .guideHeader {
        position:            relative;
        background-image:    url("../../../assets/images/bg_why3.png");
        background-size:     cover;
        background-position: -310px -199px;
        padding:             20px 10px;
        width:               100%;
        display:             flex;
        align-items:         center;
        justify-content:     center;

        @media (max-width: $Breakpoint_Medium) {
            background-position: unset;
        }

        > span {

            position: relative;
            display:  flex;
            width:    $Desktop_Minimum_Width;
            @include typoH2;
            color:    $Color_Text_Invert;
        }
    }

    .contentGuide {
        position:        relative;
        display:         flex;
        max-width:       $Desktop_Minimum_Width;
        justify-content: center;
        align-content:   center;
    }

    .gradientW2P {
        background: $Gradient_CYMK;
        position:   relative;
        display:    flex;
        height:     5px;
        width:      100%;
    }


    div.item {

        position:       relative;
        padding-bottom: 60px;
        height:         100%;

        @media (max-width: $Desktop_Minimum_Width) {
            padding-left:  10px;
            padding-right: 10px;
        }

        h3 {
            @include typoH3;
            color:         $Color_Secondary;
            margin-bottom: 10px;
            margin-top:    20px;
            padding:       0;
        }

        ul {
            position:        relative;
            display:         flex;
            word-break:      keep-all;
            flex-direction:  row;
            flex-wrap:       wrap;
            justify-content: flex-start;
            list-style:      unset;
            padding:         0;

            li {
                background:    $Color_Primary_Light;
                color:         $Color_Primary;
                padding:       5px 10px;
                display:       flex;
                border-radius: 20px;
                font-size:     0.8rem;
                word-break:    keep-all;
                white-space:   nowrap;
                margin:        0 5px 5px 0;
            }
        }

        h4 {
            @include typoH4;
        }

        ol {
            position:       relative;
            display:        flex;
            flex-direction: column;
            //padding:        0;
            padding-left:   5px;
            list-style:     none;

            li {

                &:before {
                    @extend %fa-icon;
                    @extend .fas;
                    content:      fa-content($fa-var_chevron-circle-right);
                    color:        $Color_Secondary;
                    margin-right: 10px;
                }

                @include typeP;

            }
        }

        ion-button {
            position: absolute;
            bottom:   5px;
            width:    100%;

            @media (max-width: $Desktop_Minimum_Width) {
                width: 95%;
            }

        }
    }
}
