@import "../../../scss/Settings";
@import "../../../scss/Colors";

.WebAppsTeaser {

    position:         relative;
    display:          flex;
    flex-direction:   column;

    background-image: url("../../../assets/images/bg_why3.png");
    background-size:  cover;

    width:            100%;
    height:           470px;

    justify-content:  center;
    align-items:      center;

    .scene {
        position:       relative;
        display:        flex;
        height:         470px;
        width:          1200px;
        transform:      scale(1);
        padding:        50px;
        flex-direction: column;

        .smartphone {
            position: absolute;
            width:    10%;
        }

        .label {
            position:    absolute;
            font-family: Handwrite, sans-serif;
            color:       $Color_Secondary;
            font-size:   30px;
        }

        .smartphoneAndroid {
            position: absolute;
            width:    10%;
        }

        .pwa {
            position: absolute;
            top:      120px;
            width:    20%;
        }

        .desktop {

            position: absolute;
            width:    15%;

        }

        h1 {
            position: relative;
            color:    $Color_Secondary;
        }

        h2 {
            position:  relative;
            color:     #ffffff;
            max-width: 50%;
        }


        .buttonWrapper {
            position: relative;

            ion-button {
                position:   relative;
                margin-top: 30px;
                max-width:  50%;
            }
        }

    }
}
