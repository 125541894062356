
.LazyLoader {
	position:       relative;
	display:        flex;
	flex-direction: column;

	opacity:        0.4;
	transition:     500ms ease-out;

	top:            120px;

	&.show {
		opacity: 1;
		top:     0;
	}

	.placeholder {
		position:        relative;
		display:         flex;
		flex-direction:  column;

		justify-content: center;
		align-items:     center;

		svg {
			opacity:   1;
			color:     #999999;
			font-size: 2rem;
		}


	}

}
