@import "../../../scss/Settings";
@import "../../../scss/Text";

.WebAppBurger {

	position:        relative;
	display:         flex;
	justify-content: center;

	> .inner {
		position:                 relative;
		display:                  grid;
		grid-template-areas: 	"lineY a1 a2" "lineY b1 b2" "lineBlock lineX lineX";
		grid-template-columns: 	21px 1fr 1fr;
		grid-template-rows: 	1fr 1fr 21px;

		height:                   100%;
		width:                    100%;
		max-width:                500px;
		min-width:                500px;
		min-height:               500px;
		max-height:               500px;

		@media (max-width: $Breakpoint_Small) {
			transform: scale(0.6);
		}

		align-self:               center;
	}

	.lineBlock {
		position:      relative;
		grid-area:     lineBlock;
		border-radius: 10px;
		display:       flex;
		width:         100%;
		height:        100%;
		background:    #DDDDDD;
	}

	.lineY {
		position:        relative;
		grid-area:       lineY;
		display:         flex;
		justify-content: center;
		align-items:     center;

		span {
			position:   absolute;
			display:    flex;
			width:      3px;
			background: #DDDDDD;

			svg {
				position:  relative;
				left:      -7px;
				top:       -10px;
				color:     #DDDDDD;
				font-size: 29px;
			}
		}

		label {
			@include typeStyledSmall;
			position:       relative;
			transform:      rotate(90deg);
			text-transform: uppercase;
			right:          14px;
			color:          #999999;
		}
	}

	.lineX {
		position:        relative;
		grid-area:       lineX;
		display:         flex;
		justify-content: center;
		align-items:     center;

		span {
			position:        absolute;
			display:         flex;
			height:          3px;
			background:      #DDDDDD;
			justify-content: flex-end;

			svg {
				position:  relative;
				right:     -4px;
				top:       -13px;
				color:     #DDDDDD;
				font-size: 29px;
				transform: rotate(90deg);
			}
		}

		label {
			@include typeStyledSmall;
			position:       relative;
			text-transform: uppercase;
			top:            14px;
			color:          #999999;
		}
	}

	@mixin itemTags {
		position:        relative;
		display:         flex;
		justify-content: center;
		align-items:     center;

		> div {
			position:       relative;
			left:           -30px;
			padding:        20px 20px 5px 20px;
			border-radius:  10px;
			box-sizing:     content-box;
			display:        flex;
			flex-direction: column;

			> img {
				position: relative;
			}

			> label {
				@include typeStyledSmall;
				color:           #999999;
				width:           100%;
				display:         flex;
				justify-content: center;
				align-items:     center;
				font-size:       1rem;
				box-sizing:      content-box;
				padding:         5px 0 0 0;
			}
		}
	}

	.a1 {
		grid-area: a1;
		@include itemTags;

		> div {
			background: #EEEEEE;
		}
	}

	.a2 {
		grid-area: a2;

		@include itemTags;

		> div {
			background: $Color_Secondary_Light;

			> img {
				transform: rotate(12deg);
			}
		}
	}

	.b1 {
		grid-area: b1;
	}

	.b2 {
		grid-area: b2;

		@include itemTags;

		> div {
			background: #EEEEEE;
		}
	}


}
