@import "./Colors";
@import "./Settings";

@mixin textTopLabel {
	color:          $Color_Text_Light;
	font-family:    Medium, sans-serif;
	text-transform: uppercase;
	font-size:      1rem;
}


@mixin typoH1 {
	font-family: Medium, sans-serif;
	color:       $Color_Primary;
	font-size:   2.2rem;
	line-height: 1.6;

	span {
		font-family: inherit;
		font-size:   inherit;
		line-height: inherit;
		color:       $Color_Secondary;
	}

	@media (max-width: $Breakpoint_Small) {
		font-size:   2rem;
		line-height: 1.5;
	}

	@media (max-width: $Breakpoint_Medium) {
		font-size:   2.2rem;
		line-height: 1.4;
	}

}

@mixin typoH2 {
	font-family:    Medium, sans-serif;
	color:          $Color_Secondary;
	font-size:      1.8rem;
	line-height:    1.5;
	letter-spacing: 0.5px;

	@media (max-width: $Breakpoint_Small) {
		font-size:   1.6rem;
		line-height: 1.3;
	}

	@media (max-width: $Breakpoint_Medium) {
		font-size:   1.8rem;
		line-height: 1.3;
	}
}

@mixin typoH3 {
	font-family:    Medium, sans-serif;
	color:          $Color_Text_Light;
	font-size:      1.4rem;
	line-height:    1.4;
	text-transform: inherit;

	@media (max-width: $Breakpoint_Small) {
		font-size:   1.2rem;
		line-height: 1.2;
	}

	@media (max-width: $Breakpoint_Medium) {
		font-size:   1.3rem;
		line-height: 1.2;
	}
}

@mixin typoH4 {
	font-family:    Medium, sans-serif;
	color:          $Color_Text_Light;
	font-size:      1.3rem;
	line-height:    1.4;
	text-transform: uppercase;
	margin:         0 0 10px 0;

	@media (max-width: $Breakpoint_Small) {
		font-size:   1.2rem;
		line-height: 1.2;
	}

	@media (max-width: $Breakpoint_Medium) {
		font-size:   1.3rem;
		line-height: 1.2;
	}
}

@mixin typeStyled {

	font-family: Medium, sans-serif;
	color:       $Color_Text;
	font-size:   1.4rem;
	line-height: 1.6;

	@media (max-width: $Breakpoint_Medium) {
		font-size:   1.2rem;
		line-height: 1.4;
	}
}

@mixin typeStyledSmall {

	font-family: Medium, sans-serif;
	color:       $Color_Text;
	font-size:   1.2rem;
	line-height: 1.4;

	@media (max-width: $Breakpoint_Medium) {
		font-size:   1.0rem;
		line-height: 1.2;
	}
}


@mixin typeP {

	font-family: Light, sans-serif;
	color:       $Color_Text;
	font-size:   1.2rem;
	line-height: 1.4;
}

@mixin typoContent {

	font-family: Light, sans-serif;
	color:       $Color_Text;
	font-size:   1.0rem;
	line-height: 1.4;

	@media (max-width: $Breakpoint_Medium) {
		font-size:   0.9rem;
		line-height: 1.3;
	}

}
