@import "../scss/Animations";
@import "../scss/Settings";
@import "../scss/Colors";

.content {
	position:       relative;
	width:          100%;
	display:        flex;
	flex-direction: column;
}

@keyframes bgMoveWhy {
	0% {
		background-position-x: 100%;
		background-position-y: 50%;
	}
	25% {
		background-position-x: 96%;
		background-position-y: 52%;
	}
	50% {
		background-position-x: 98%;
		background-position-y: 48%;
	}
	100% {
		background-position-x: 100%;
		background-position-y: 50%;
	}
}

.WhyBitsinmotion {

	position:              relative;
	display:               flex;
	flex-direction:        column;
	height:                max-content;
	min-height:            300px;
	width:                 100%;

	background-image:      url("../assets/images/bg_why3.png");
	background-size:       cover;
	background-position-x: 100%;
	background-position-y: 50%;

	justify-content:       flex-start;
	align-items:           center;
	animation:             bgMoveWhy 12s infinite;
	transition:            200ms ease-out;


	.inner {

		position:       relative;
		display:        flex;
		flex-direction: column;
		max-width:      1200px;
		width:          100%;
		left:           -100px;
		@include transitionEaseInOutFast(200ms);
		opacity:        0;

		padding-top:    50px;
		padding-right:  50px;
		padding-left:   50px;

		@media (max-width: $Breakpoint_Medium) {
			padding-right: 25px;
			padding-left:  25px;
		}

		@media (max-width: $Breakpoint_Small) {
			padding-right: 10px;
			padding-left:  10px;
		}

		&.show {
			left:    0;
			opacity: 1;
		}

		@media (max-width: $Breakpoint_Large) {
			padding-left:  20px;
			padding-right: 20px;
		}
	}

	h1 {
		position: relative;
		color:    #FFFFFF;
		width:    75%;
		top:      80px;
		opacity:  0;
		@include transitionEaseInOutFast(150ms);

		.cymk {
			position:    relative;
			font-family: inherit;
			font-size:   inherit;

			&::after {
				position:   absolute;
				width:      100%;
				height:     2px;
				background: $Gradient_CYMK;
				display:    flex;
				content:    " "
			}
		}


		> span:last-child {
			border-bottom: 1px dotted $Color_Secondary;
			font-family:   inherit;
			font-size:     inherit;


		}

		&.show {
			transform: translateY(-80px);
			opacity:   1;
		}

		@media (max-width: $Breakpoint_Large) {
			width: 100%;
		}
	}

	h2 {
		position:    relative;
		color:       #FFFFFF;
		width:       60%;
		line-height: 1.6;
		top:         60px;
		@include transitionEaseInOutFast(450ms);
		opacity:     0;

		&.show {
			transform: translateY(-60px);
			opacity:   1;
		}

		@media (max-width: $Breakpoint_Large) {
			width: 100%;
		}
	}

	ion-button {
		position: relative;

		width:    max-content;

		opacity:  0;
		@include transitionEaseInOutFast(1000ms);

		&.show {
			opacity: 1;
		}

		&.sublineButton {

			top:  10px;
			left: -15px;

			ion-label {
				word-wrap:     break-word;
				display:       flex;
				overflow-wrap: break-word;
			}

			svg {
				position:    relative;
				font-size:   2rem;
				margin-left: 10px;
			}

			@media (max-width: $Breakpoint_Small) {

				span {
					display: none;
				}
			}
		}
	}

	@keyframes bgMove {
		0% {
			transform: translateX(0%) rotate(0deg);
			opacity:   1;
		}
		25% {
			transform: translate(5%, 5%) rotate(8deg);
			opacity:   0.6;
		}
		50% {
			transform: translate(9% 2%) rotate(16deg);
			opacity:   0.5;
		}
		75% {
			transform: translate(-10% -10%) rotate(-5deg);
			opacity:   0.8;
		}
		100% {
			transform: translateX(0%) rotate(0deg);
			opacity:   1;
		}
	}

	@keyframes iconMove {
		0% {
			transform: translateX(0%) rotate(0deg);
		}
		25% {
			transform: translate(15%, 10%) rotate(3deg);
		}
		50% {
			transform: translate(9% 2%) rotate(5deg);
		}
		75% {
			transform: translate(-15% -15%) rotate(-5deg);
		}
		100% {
			transform: translateX(0%) rotate(0deg);
		}
	}

	@keyframes iconMove2 {
		0% {
			transform: translateX(0%) rotate(0deg);
		}
		25% {
			transform: translate(-15%, -10%) rotate(-3deg);
		}
		50% {
			transform: translate(-5% 2%) rotate(-5deg);
		}
		75% {
			transform: translate(15% 15%) rotate(8deg);
		}
		100% {
			transform: translateX(0%) rotate(0deg);
		}
	}

	.puzzle {

		position:       absolute;
		display:        flex;
		flex-direction: column;
		width:          200px;
		height:         200px;
		right:          5%;
		bottom:         30%;
		opacity:        0;
		transition:     1000ms ease-in;

		&.show {
			opacity: 1;
		}

		.background {
			position:      absolute;
			width:         100%;
			height:        100%;
			background:    $Gradient_CYMK;
			border-radius: 50px;
			filter:        blur(24px);
			animation:     bgMove 10s infinite;
		}

		.foreground {
			position: absolute;
			width:    100%;
			height:   100%;
			left:     15%;
			top:      10%;
		}

		@media (max-width: $Breakpoint_Large) {
			position:        relative !important;
			width:           100%;
			justify-content: center;
			align-items:     center;
			top:             0;
			left:            0;
			margin-top:      30px;

			.background {
				width: 200px;
			}

			.foreground {
				width:       200px;
				display:     flex;
				left:        unset;
				top:         unset;
				margin-left: 12%;
			}
		}

		.logo {
			position:  absolute;
			width:     25%;
			top:       30%;
			left:      20%;
			animation: iconMove2 10s infinite;
		}

		.brand {
			position:  absolute;
			animation: iconMove 10s infinite;

			&.wp {
				font-size:       3rem;
				color:           #FFFFFF;
				top:             20%;
				left:            50%;
				animation-delay: 1s;
			}

			&.sf {
				font-size:       3rem;
				color:           #FFFFFF;
				top:             10%;
				left:            0%;
				animation-delay: 0s;
				animation:       iconMove2 10s infinite;
			}

			&.sw {
				font-size:       3rem;
				color:           #FFFFFF;
				top:             60%;
				left:            15%;
				animation-delay: 2s;
			}
		}


	}

}
