@import "../scss/Settings";
@import "../scss/Colors";

.title {

	position:        relative;
	display:         flex;
	flex-direction:  column;

	justify-content: center;
	align-items:     center;
	padding:         30px 50px 30px 0;


	@media (max-width: $Breakpoint_Small) {
		padding: 10px 50px 10px 0;
	}

	@media (max-width: $Breakpoint_Medium) {
		padding: 15px 50px 15px 0;
	}


	.bitsinmotion {
		position:  relative;
		max-width: 250px;
		display:   flex;

		@media (max-width: $Breakpoint_Small) {
			max-width: 200px;
		}

	}
}

.menuButton {
	display: none;
	@media (max-width: $Breakpoint_Large) {
		display: flex;
	}


}

ion-header {

	position:        relative;
	width:           100%;
	height:          min-content;
	display:         flex;
	flex-direction:  column;
	justify-content: center;
	align-items:     center;
	background:      #FFFFFF;

	.menuSegment {

		position:   relative;
		max-width:  1200px;
		background: #FFFFFF;

		@media (max-width: $Breakpoint_Small) {

			display: none;
		}

		ion-segment-button {

			position:           relative;
			--indicator-color:  #70C041;
			--indicator-height: 3px;

			> div {

				position:        relative;
				display:         flex;
				flex-direction:  row;
				align-items:     center;
				justify-content: center;


				ion-img {
					color:        blue;
					width:        2rem;
					margin-right: 10px;

					@media (max-width: $Breakpoint_Small) {

						width: 1.5rem;
					}

				}


				ion-label {

					@media (max-width: $Breakpoint_Large) {
						display: none;
					}
				}
			}
		}
	}
}


