@import "../../scss/Colors";
@import "../../scss/Settings";
@import "../../scss/Text";

.AppFooter {

	position:        relative;
	background:      #3E4660;
	color:           #FFFFFF;
	font-family:     Regular, sans-serif;
	padding:         20px 20px 40px 20px;
	width:           100%;
	display:         flex;
	justify-content: center;

	.footerInner {
		position:       relative;
		max-width:      1200px;
		width:          100%;
		display:        flex;
		flex-direction: column;

		.footerColumn {

			position:       relative;
			display:        flex;
			flex-direction: column;

			h3 {
				color: $Color_Secondary;

			}

			a {
				color:         #FFFFFF;
				margin-bottom: 10px;
				font-family:   Light, sans-serif;
				font-size:     1.2rem;
				line-height:   1.4rem;
			}
		}
	}
}
