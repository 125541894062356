@import "../scss/Settings";
@import "../scss/Colors";
@import "../scss/Content";

.content {

	@include contentBox;

	//position:       relative;
	//width:          100%;
	//display:        flex;
	//flex-direction: column;
	//align-items:    center;
	//
	//padding-right:  50px;
	//padding-left:   50px;
	//
	//@media (max-width: $Breakpoint_Medium) {
	//	padding-right: 25px;
	//	padding-left:  25px;
	//}
	//
	//@media (max-width: $Breakpoint_Small) {
	//	padding-right: 10px;
	//	padding-left:  10px;
	//}
	//
	//.inner {
	//	position:       relative;
	//	display:        flex;
	//	flex-direction: column;
	//	max-width:      1200px;
	//	width:          100%;
	//
	//	h1 {
	//		width: 100%;
	//	}
	//}
}

.animationW2pWrapper {

	position:        relative;
	display:         flex;
	flex-direction:  column;
	justify-content: center;
	align-items:     center;

	height:          450px;
	overflow:        hidden;

	//margin-bottom:   30px;

	@media (max-width: $Breakpoint_Medium) {
		height: 320px;
	}
	@media (max-width: $Breakpoint_Small) {
		height: 250px;
	}
	@media (max-width: $Breakpoint_Xs) {
		height: 200px;
	}


}
