@import "../../../scss/Text";

.WebAppComparisonTable {

	$bordercolor: #EEEEEE;

	.label {
		@include typoH3;
		border-bottom: 1px solid $bordercolor;
	}

	.columnhead {

		display:         flex;
		justify-content: center;
		border-bottom:   1px solid $bordercolor;
		@include textTopLabel
	}

	.tags {
		display:         flex;
		justify-content: center;
		border-bottom:   1px solid $bordercolor;
		border-left:     1px solid $bordercolor;

		.chipList {
			display:         flex;
			flex-direction:  row;
			flex-wrap:       wrap;
			justify-content: space-evenly;
		}
	}

}
