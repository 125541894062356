@import "../../scss/Colors";
@import "../../scss/Animations";
@import "../../scss/Text";
@import "../../scss/Settings";

.AnimationW2p {

	position:        relative;
	width:           100%;
	display:         flex;
	flex-direction:  column;
	height:          450px;
	justify-content: center;
	align-items:     center;

	background-image:      url("../../assets/images/bg_paper_1200.png");
	background-size:       cover;
	background-position-x: right;
	background-position-y: center;

	.inner {

		position:  relative;
		width:     1200px;
		max-width: 1200px;
		height:    300px;
		top: -20px;

		@media (max-width: $Breakpoint_Small) {
			left: 10px;
		}

		ion-img {
			position: absolute;
			@include transitionEaseInOutFast(300ms);
		}

		span {
			position:    absolute;
			font-family: Handwrite, sans-serif;
			font-size:   2rem;
			color:       $Color_Secondary;
			@include transitionEaseInOutFast(300ms);
		}

		//editor
		.browser {
			top:     25%;
			left:    5%;
			width:   10%;
			opacity: 0;

			&.show {
				left:    10%;
				opacity: 1;
			}
		}

		.mobile {
			top:     55%;
			left:    5%;
			width:   6%;
			opacity: 0;

			&.show {
				top:       48%;
				left:      18%;
				opacity:   1;
				transform: rotate(25deg);
			}
		}

		.printFlyer {
			top:             25%;
			left:            15%;
			width:           0%;
			opacity:         0;
			animation:       productMove 4s infinite;
			animation-delay: 0s;

			&.show {
				top:       0%;
				left:      5%;
				width:     6%;
				opacity:   1;
				transform: rotate(25deg);
			}

			&.fly {
				top:   10%;
				left:  82%;
				width: 4%;
			}

		}

		.printCalendar {
			top:             25%;
			left:            15%;
			width:           0%;
			opacity:         0;
			animation:       productMove 5s infinite;
			animation-delay: 1s;


			&.show {
				top:     -5%;
				left:    13%;
				width:   5%;
				opacity: 1;

			}

			&.fly {
				top:   30%;
				left:  84%;
				width: 4%;
			}


		}

		.printBag {
			top:             25%;
			left:            15%;
			width:           0%;
			opacity:         0;
			animation:       productMove 3s infinite;
			animation-delay: 1.5s;


			&.show {
				top:       -2%;
				left:      18.5%;
				width:     7%;
				opacity:   1;
				transform: rotate(12deg);
			}

			&.fly {
				top:   52%;
				left:  81%;
				width: 5%;
			}

		}

		.photo {
			top:       25%;
			left:      15%;
			width:     0%;
			opacity:   1;
			transform: rotate(-12deg);

			&.show {
				top:       -2%;
				left:      12%;
				width:     7%;
				opacity:   1;
				transform: rotate(12deg);
			}

			&.fly {
				top:     82%;
				left:    74%;
				width:   5%;
				opacity: 0.8;
			}

		}

		.like {
			top:       78%;
			left:      75%;
			width:     0%;
			opacity:   0;
			transform: rotate(-12deg);

			&.show {
				top:       68%;
				left:      75%;
				width:     5%;
				opacity:   1;
				transform: rotate(12deg);
			}
		}

		.textEditor {
			top:     100%;
			left:    8%;
			opacity: 0;

			&.show {
				top:     80%;
				opacity: 1;
			}
		}

		.arrow1 {
			top:              35%;
			left:             25%;
			width:            1%;
			transform:        rotate(350deg);
			opacity:          0;
			transition:       350ms ease-in-out;
			transform-origin: center center;

			&.show {
				top:       35%;
				width:     10%;
				opacity:   1;
				transform: rotate(300deg);
			}


		}

		@keyframes cloudmove {
			0% {
				transform: translateX(0%);
			}
			50% {
				transform: translateX(5%);
			}
			100% {
				transform: translateX(0%);
			}
		}

		@keyframes logomove {
			0% {
				transform: translateX(0%);
			}
			25% {
				transform: translate(5%, 5%);
			}
			50% {
				transform: translate(4% 2%);
			}
			75% {
				transform: translate(-3% -5%);
			}
			100% {
				transform: translateX(0%);
			}
		}

		@keyframes productMove {
			0% {
				transform: translateX(0%) rotate(0deg);
			}
			25% {
				transform: translate(5%, 5%) rotate(8deg);
			}
			50% {
				transform: translate(4% 2%) rotate(16deg);
			}
			75% {
				transform: translate(-3% -5%) rotate(-5deg);
			}
			100% {
				transform: translateX(0%) rotate(0deg);
			}
		}

		//cloud
		.cloudApi {
			position: absolute;
			left:     30%;
			top:      0%;
			width:    30%;
			height:   100%;
			display:  flex;

			.textCloud {
				position: absolute;
				top:      -5%;
				left:     20%;
				opacity:  0;

				&.show {
					top:     0%;
					opacity: 1;
				}
			}

			.cloud1 {
				position:        absolute;
				top:             -5%;
				left:            -100%;
				width:           60%;
				opacity:         0;

				&.show {
					left:    0%;
					opacity: 1;
				}

				animation:       cloudmove 5s infinite;
				animation-delay: 1s;
			}

			.cloud2 {
				position:        absolute;
				top:             20%;
				left:            60%;
				width:           30%;
				opacity:         0;

				animation:       cloudmove 4s infinite;
				animation-delay: 0s;

				&.show {
					left:    55%;
					opacity: 1;
				}
			}

			.cloud3 {
				position:        absolute;
				top:             25%;
				left:            0%;
				width:           52%;
				opacity:         0;
				transition:      500ms ease-in-out;
				animation:       cloudmove 6s infinite;
				animation-delay: 1.5s;

				&.show {
					left:    20%;
					opacity: 1;
				}
			}

			.logo {
				position:   absolute;
				top:        35%;
				left:       40%;
				width:      15%;
				opacity:    0;
				transition: 1000ms ease-in-out;
				animation:  cloudmove 3s infinite;

				&.show {
					top:     25%;
					left:    40%;
					opacity: 1;
				}
			}
		}

		// printer
		.outPrint {
			position: absolute;
			left:     60%;
			top:      0%;
			width:    30%;
			height:   100%;
			display:  flex;

			.textPrint {
				position: absolute;
				top:      0%;
				left:     60%;
				opacity:  0;

				&.show {
					left:    30%;
					opacity: 1;
				}
			}

			.textPDF {
				position:  absolute;
				top:       22%;
				left:      -4%;
				width:     60%;
				color:     #999999;
				font-size: 1.2rem;
				opacity:   0;

				&.show {
					top:     12%;
					opacity: 1;
				}
			}

			.arrow3 {
				position:  absolute;
				top:       10%;
				left:      -10%;
				width:     30%;
				transform: rotate(100deg);
				opacity:   0;

				&.show {
					left:      -5%;
					opacity:   1;
					transform: rotate(150deg);
				}
			}

			.printer {
				position:  absolute;
				top:       13%;
				left:      25%;
				width:     42%;
				opacity:   0;
				transform: rotate(30deg);

				&.show {
					left:      25%;
					top:       13%;
					opacity:   1;
					transform: rotate(0deg);
				}
			}
		}

		// social
		.outSocial {
			position: absolute;
			left:     55%;
			top:      60%;
			width:    30%;
			height:   100%;
			display:  flex;

			.textSocial {
				position: absolute;
				top:      60%;
				left:     6%;
				opacity:  0;

				&.show {
					top:     50%;
					opacity: 1;
				}
			}

			.textBranded {
				position:  absolute;
				top:       35%;
				left:      -18%;
				width:     60%;
				color:     #999999;
				font-size: 1.2rem;
				transform: rotate(70deg);
				opacity:   0;

				&.show {
					top:       28%;
					left:      -15%;
					opacity:   1;
					transform: rotate(20deg);
				}

			}

			.arrow2 {
				position:  absolute;
				top:       -17%;
				left:      -15%;
				width:     40%;
				transform: rotate(150deg);
				opacity:   0;

				&.show {
					top:     -12%;
					left:    -10%;
					opacity: 1;
				}
			}

			.socialmedia {
				position: absolute;
				top:      13%;
				left:     35%;
				width:    32%;
				opacity:  0;

				&.show {
					top:     13%;
					left:    25%;
					opacity: 1;
				}

			}
		}

	}
}
