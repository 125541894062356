$Color_Primary:          #26406C !default;
$Color_Primary_Light:    #C4DFE8 !default;
$Color_Secondary:        #70C041 !default;
$Color_Secondary_Light:  #E6F8D8 !default;
$Color_Tertiary:         #368A03 !default;
$Color_Background:       #F5F5F5 !default;
$Color_Text:             #292929 !default;
$Color_Text_Light:       #B4B4B4 !default;
$Color_Text_Invert:      #FFFFFF !default;

$Color_Text_Placeholder: red !default;

$Color_Danger:           #EB3E34 !default;
$Color_Danger_Light:     #F77C7E !default;
$Color_Danger_LightMin:  #FFD8C0 !default;

$Color_Warning:          #FFB734 !default;
$Color_Warning_Light:    #FFE67D !default;
$Color_Warning_LightMin: #FFFEBA !default;

$Color_Success:          #1A861B !default;
$Color_Success_Light:    #C3E0B6 !default;

$Gradient_Primary:       radial-gradient(circle, #3E4660, #26406C) !default;
$Gradient_CYMK:          linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 212, 255, 1) 0%, rgba(241, 0, 255, 1) 35%, rgba(255, 250, 0, 1) 73%, rgba(0, 0, 0, 1) 100%) !default;
$Gradient_CYMK_Radial:   radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(255, 250, 0, 1) 14%, rgba(241, 0, 255, 1) 40%, rgba(0, 212, 255, 1) 100%) !default;
$Background_Light:       #FBF3E9;
$Background:             #ffffff !default;



