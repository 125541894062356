@import "../scss/Settings";
@import "../scss/Colors";
@import "../scss/Content";

.ContentBlock {

	position: relative;

	@include contentBox;

	> div {

		> span,
		.columnTitle {
			@include textTopLabel;
		}

	}


}
