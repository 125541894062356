@import "Colors";
@import "Settings";
@import "app";
@import "Text";

@font-face {
	font-family: Regular;
	src:         url("../assets/webfonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
	font-family: Light;
	src:         url("../assets/webfonts/Roboto/Roboto-Light.ttf");
}

@font-face {
	font-family: Medium;
	src:         url("../assets/webfonts/Roboto_Slab/static/RobotoSlab-Medium.ttf");
}

@font-face {
	font-family: Bold;
	src:         url("../assets/webfonts/Roboto_Slab/static/RobotoSlab-Bold.ttf");
}

@font-face {
	font-family: Handwrite;
	src:         url("../assets/webfonts/Architects_Daughter/ArchitectsDaughter-Regular.ttf");
}

* {
	font-family: Regular, sans-serif;
}


h1 {
	@include typoH1;
	margin-top: 10px;
}

h2 {
	@include typoH2;
}

h3 {
	@include typoH3;
}

p {
	@include typeP;
}


.Background_Primary {
	--background: $Color_Primary;
	--color:      $Color_Primary;
}

.NoMargin {
	margin: 0 !important;
}

.NoDisplay {
	display: none !important;
}

.FlexCenter {
	display:         flex;
	flex-direction:  column;
	justify-content: center !important;
	margin:          0 auto;
}

.FlexLeft {
	display:         flex;
	flex-direction:  column;
	justify-content: left !important;
}

.FlexRight {
	display:         flex;
	flex-direction:  column;
	justify-content: right !important;
}

.WidthMinContent {
	width: min-content !important;
}

.Padding {
	padding: 10px;
}

.LoginIntro {
	padding: 20px;
}

.Headline_Section {
	font-family: OpenSansLight, sans-serif;
	font-size:   1.4em;
	color:       $Color_Primary;
	margin:      0 0 10px 0;
}

h1.List_Header {
	font-family: OpenSansLight, sans-serif;
	font-size:   1.3em;
	background:  $Color_Primary;
	color:       #FFFFFF;

	svg {
		margin: 0 10px 0 0;
	}
}

h2.List_Header {
	font-family:   OpenSansLight, sans-serif;
	font-size:     1.2em;
	color:         $Color_Primary;
	border-bottom: 1px dotted $Color_Primary;
	padding:       0 0 3px 0;
	width:         100%;
	margin:        15px 10px 10px 10px;
}

.List_Item_Warning {
	color: red !important;
}

.List_Item_Warning_Danger {
	color: red !important;
}

.List_Item_Warning_Warning {
	color: $Color_Primary !important;
}

.List_Item_Warning_Ok {
	color: green !important;
}

.List_Item_Mail {

	&.Unread {
		font-weight: bold;
	}
}

.List_Avatar_Icon {
	position:   relative;
	box-sizing: border-box;
	display:    flex;
	font-size:  33px;
	width:      40px;
	height:     40px;
	margin:     8px 16px 8px 0;
	color:      #999999;
}


.DashItem_Badge {
	position:        relative;
	display:         flex;
	justify-content: center;
	box-sizing:      border-box;
	border-radius:   30px;
	padding:         2px 9px;
	height:          25px;
	width:           25px;
	font-size:       1.6em;
	font-weight:     400;
	background:      transparent;
	color:           #333333;
}


@keyframes leaves {
	0% {
		transform:         scale(1.0);
		-webkit-transform: scale(1.0);
	}

	100% {
		transform:         scale(1.3);
		-webkit-transform: scale(1.3);
	}
}

.List_Overdue_Icon {
	position:   relative;
	box-sizing: border-box;
	display:    flex;
	font-size:  25px;
	width:      30px;
	height:     30px;
	margin:     8px 16px 8px 0;
	color:      green;

	&.Danger {
		color:             red !important;
		animation:         leaves 1s ease-in-out infinite alternate;
		-webkit-animation: leaves 1s ease-in-out infinite alternate;

	}

	&.Warning {
		color: orange !important;

	}
}

.List_Time_Icon {
	position:   relative;
	box-sizing: border-box;
	display:    flex;
	font-size:  12px;
	margin:     3px 10px 0 0;
	color:      #666655;
}


.JustifyCenter {
	position:        relative;
	display:         flex;
	justify-content: center;
}


h1, h2 {
	color:       $Color_Primary;
	font-family: Medium, sans-serif;
}


ion-button {
	ion-label {
		margin: 0 5px;
	}
}
